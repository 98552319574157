import { createAction } from '@reduxjs/toolkit';

export const getServicesRequest = createAction('GET_SERVICES_REQUEST');
export const getServicesSuccess = createAction('GET_SERVICES_SUCCESS');
export const getServicesFailure = createAction('GET_SERVICES_FAILURE');

export const editServiceRequest = createAction('EDIT_SERVICE_REQUEST');
export const editServiceSuccess = createAction('EDIT_SERVICE_SUCCESS');
export const editServiceFailure = createAction('EDIT_SERVICE_FAILURE');

export const createServiceRequest = createAction('CREATE_SERVICE_REQUEST');
export const createServiceSuccess = createAction('CREATE_SERVICE_SUCCESS');
export const createServiceFailure = createAction('CREATE_SERVICE_FAILURE');

export const getServiceRequest = createAction('GET_SERVICE_REQUEST');
export const getServiceSuccess = createAction('GET_SERVICE_SUCCESS');
export const getServiceFailure = createAction('GET_SERVICE_FAILURE');
