import { ENV, HOST_API, PORT_API } from '../../config';
import axios from '../../utils/axios';

const tenantUrl = localStorage.getItem('tenant_url');

class ReservationServices {
  getUrl() {
    return HOST_API;
  }

  getTenantUrl() {
    const protocol = ENV === 'local' ? 'http' : 'https';
    const url = `${protocol}://${tenantUrl}${PORT_API}`;
    return url;
  }

  getHeaders() {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  }

  getReservations = async (queryParams) => {
    try {
      return axios.get(`${this.getTenantUrl()}/api/t-reservations`, {
        params: queryParams,
        headers: this.getHeaders(),
      });
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  getReservationDetails = async (id) => {
    try {
      return axios.get(`${this.getTenantUrl()}/api/t-reservations/${id}`, {
        headers: this.getHeaders(),
      });
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  confirmReservation = async ({ id, values }) => {
    try {
      return axios.put(`${this.getTenantUrl()}/api/t-reservations/${id}/confirm`, values);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  cancelReservation = async ({ id, values }) => {
    try {
      return axios.put(`${this.getTenantUrl()}/api/t-reservations/${id}/cancel`, values);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  search = async (name, query) => {
    const params = new URLSearchParams({
      name,
      ...query,
    });

    try {
      return axios.get(`${this.getTenantUrl()}/api/contacts?${params.toString()}`);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  toggleContactArchive = async (id) => {
    try {
      return axios.put(`${this.getTenantUrl()}/api/contacts/${id}/archive`);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  checkAvailability = async (propertyData) => {
    try {
      return axios.post(`${this.getTenantUrl()}/api/t-reservations/check-availability`, {
        property_id: propertyData.propertyId,
        date: propertyData.date,
      });
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  reserve = async (reserveData) => {
    try {
      return axios.post(`${this.getTenantUrl()}/api/t-reservations/reserve`, {
        property_id: reserveData.property_id,
        check_in_date: reserveData.check_in_date,
        check_out_date: reserveData.check_out_date,
        mobile_number: reserveData.mobile_number,
        name: reserveData.name,
        email: reserveData.email,
        payment_method: reserveData.payment_method,
      });
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };
}

export { ReservationServices };
export default new ReservationServices();
