import { Box, Button, Dialog, Divider, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { Loader } from '../../components/Loader';
import useLocales from '../../hooks/useLocales';
import * as serviceActions from '../../models/t-services/actions';
import { dispatch } from '../../redux/store';

export const addServiceSchema = Yup.object({
  name_ar: Yup.string().required('Required'),
  name_en: Yup.string().required('Required'),
});

const AddServiceModal = ({ open, onClose }) => {
  const { translate } = useLocales();
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const callback = (type, response) => {
    setLoading(false);
    if (type === 'success') {
      toast.success(translate('service.successCreation'));
      navigate(`/maktb/services/${response.data.id}`);
    } else {
      toast(response, { type: 'error' });
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      name_ar: '',
      name_en: '',
    },
    validationSchema: addServiceSchema,
    isInitialValid: false,
    onSubmit: (values) => {
      setLoading(true);
      dispatch(serviceActions.createServiceRequest({ ...values, callback }));
    },
  });

  return (
    <Dialog open={open} onClose={onClose}>
      <Box sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
        <Typography sx={{ p: 2 }} variant="h5">
          {translate('service.addService')}
        </Typography>

        <form onSubmit={formik.handleSubmit}>
          <button type="submit" style={{ display: 'none' }}>
            Submit
          </button>

          <TextField
            name="name_ar"
            value={formik.values.name_ar}
            label={translate('service.nameAr')}
            fullWidth
            sx={{ my: 2 }}
            onChange={formik.handleChange}
          />
          <TextField
            name="name_en"
            value={formik.values.name_en}
            label={translate('service.nameEn')}
            fullWidth
            sx={{ my: 2 }}
            onChange={formik.handleChange}
          />
        </form>

        <Divider sx={{ mt: 2 }} />

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
          <Button variant="outlined" sx={{ color: 'black', borderColor: '#919EAB66', mr: 2 }} onClick={onClose}>
            {translate('service.cancelButton')}
          </Button>

          <Button
            disabled={!formik.isValid || loading}
            variant="contained"
            sx={{ color: 'white' }}
            onClick={formik.submitForm}
          >
            {!loading && translate('service.createButton')}
            {loading && <Loader />}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default AddServiceModal;
