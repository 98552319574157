import { Box, Button } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import InfoBox from '../../../components/InfoBox';
import { Loader } from '../../../components/Loader';
import Page from '../../../components/Page';
import useLocales from '../../../hooks/useLocales';
import * as propertyActions from '../../../models/properties/actions';
import { selectors as propertySelectors } from '../../../models/properties/reducers';
import { FACADE_DIRECTIONS } from '../../../utils/constants';
import ListingsForm from './ListingsForm';

const propertyEditSchema = Yup.object({
  min_price: Yup.number().positive(),
  max_price: Yup.number().positive().moreThan(Yup.ref('min_price'), 'Max price must be more than Min price.'),
  facade: Yup.string().oneOf(FACADE_DIRECTIONS),
  min_area: Yup.number(),
  max_area: Yup.number().moreThan(Yup.ref('min_area'), 'Max area must be more than Min area.'),
  country_id: Yup.number(),
  city_id: Yup.number(),
  district_id: Yup.number(),
});

const EditListings = () => {
  const { translate } = useLocales();
  const dispatch = useDispatch();
  const { id } = useParams();
  const property = useSelector(propertySelectors.property);
  const updateLoading = useSelector(propertySelectors.loading);

  useEffect(() => {
    dispatch(propertyActions.getPropertyRequest(id));
  }, [id]);

  const updateCallback = (t, data) => {
    if (t === 'success') {
      toast(translate('Successfully updated'), { type: 'success', autoClose: 700 });
    } else {
      toast('Failed to update property', { type: 'error' });
    }
  };

  const toggleArchive = () => () => {
    dispatch(propertyActions.toggleArchivePropertyRequest({ id, callback: updateCallback }));
  };

  const formik = useFormik({
    initialValues: {
      id: property?.id,
      name_en: property?.name_en || '',
      name_ar: property?.name_ar || '',
      description_en: property?.description_en || '',
      description_ar: property?.description_ar || '',
      category: property?.category,
      purpose: property?.purpose,
      type: property?.type,
      year_built: property?.year_built || 0,
      street_width: property?.street_width || '',
      street_width_east: property?.street_width_east || '',
      street_width_south: property?.street_width_south || '',
      street_width_west: property?.street_width_west || '',
      selling_price: property?.selling_price || '',
      fee: property?.fee || '',
      vat: property?.vat || '',
      saturday_price: property?.saturday_price || 0,
      sunday_price: property?.sunday_price || 0,
      monday_price: property?.monday_price || 0,
      tuesday_price: property?.tuesday_price || 0,
      wednesday_price: property?.wednesday_price || 0,
      thursday_price: property?.thursday_price || 0,
      friday_price: property?.friday_price || 0,
      rent_price_monthly: property?.rent_price_monthly || '',
      rent_price_quarterly: property?.rent_price_quarterly || '',
      rent_price_semi_annually: property?.rent_price_semi_annually || '',
      rent_price_annually: property?.rent_price_annually || '',
      area: property?.area || '',
      longitude: property?.longitude || '',
      latitude: property?.latitude || '',
      number_of_floors: property?.number_of_floors || 0,
      unit_floor_number: property?.unit_floor_number || 0,
      king_beds: property?.king_beds || 0,
      single_beds: property?.single_beds || 0,
      number_of_guests: property?.number_of_guests || 0,
      number_of_kids: property?.number_of_kids || 0,
      bedrooms: property?.bedrooms || 0,
      bathrooms: property?.bathrooms || 0,
      dining_rooms: property?.dining_rooms || 0,
      living_rooms: property?.living_rooms || 0,
      majlis_rooms: property?.majlis_rooms || 0,
      maid_rooms: property?.maid_rooms || 0,
      driver_rooms: property?.driver_rooms || 0,
      mulhaq_rooms: property?.mulhaq_rooms || 0,
      storage_rooms: property?.storage_rooms || 0,
      basement_rooms: property?.basement_rooms || 0,
      elevators: property?.elevators || 0,
      pools: property?.pools || 0,
      balconies: property?.balconies || 0,
      kitchens: property?.kitchens || 0,
      gardens: property?.gardens || 0,
      parking_spots: property?.parking_spots || 0,
      facade: property?.facade || '',
      unit_number: property?.unit_number || 0,
      plan_number: property?.plan_number || '',
      plot_number: property?.plot_number || '',
      number_of_apartments: property?.number_of_apartments || 0,
      published_on_website: !!property?.published_on_website || false,
      published_on_app: !!property?.published_on_app || false,
      cover_image_url: property?.cover_image_url || '',
      is_kitchen_installed: property?.is_kitchen_installed || false,
      is_ac_installed: property?.is_ac_installed || false,
      is_parking_shade: property?.is_parking_shade || false,
      is_furnished: property?.is_furnished || false,

      country_id: property?.country?.id || '',
      city_id: property?.city?.id || '',
      district_id: property?.district?.id || '',

      photos: property?.images?.map((image) => image.url) || [],
      length: property?.length || '',
      width: property?.width || '',

      availability_status: property?.availability_status || '',
      availability_date: property?.availability_date,

      has_water: !!property?.has_water || false,
      has_electricity: !!property?.has_electricity || false,
      has_sewage: !!property?.has_sewage || false,

      advertiser_type: property?.advertiser_type || 'broker',

      rega_ad_number: property?.rega_ad_number || '',
      rega_advertiser_number: property?.rega_advertiser_number || '',

      apartments: property?.apartments || 0,
      stores: property?.stores || 0,

      is_for_farming: !!property?.is_for_farming || false,
      is_for_residential: !!property?.is_for_residential || false,
      is_for_commercial: !!property?.is_for_commercial || false,
      is_for_education: !!property?.is_for_education || false,
      is_for_health: !!property?.is_for_health || false,
      is_for_factory: !!property?.is_for_factory || false,

      owner_id: property?.owner?.id || '',
      owner: property?.owner?.name || '',

      is_wafi_ad: !!property?.is_wafi_ad || false,
      wafi_license_number: property?.wafi_license_number || '',
      sort: property?.sort || 0,

      amenities: property?.amenities || [],
      is_online_payment_enabled: !!property?.is_online_payment_enabled || false,
      is_bank_transfer_payment_enabled: !!property?.is_bank_transfer_payment_enabled || false,
      is_cash_payment_enabled: !!property?.is_cash_payment_enabled || false,

      pricing_rules: property?.pricing_rules || [],
    },
    isInitialValid: false,
    enableReinitialize: true,
    validationSchema: propertyEditSchema,
    onSubmit: (values) => {
      dispatch(propertyActions.editPropertyRequest({ ...values, callback: updateCallback }));
    },
  });

  const createdAt = property?.created_at || new Date();

  return (
    <Page title={translate('units')}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
        <InfoBox
          label={translate('unit.information')}
          number={property?.id}
          date={createdAt}
          backButtonLink="/siyaha/listings"
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'end',
              height: '100%',
              justifyContent: 'end',
            }}
          >
            <Button
              onClick={toggleArchive()}
              variant="outlined"
              disabled={updateLoading}
              sx={{
                color: '#999',
                borderColor: '#999',
                marginLeft: 2,
                '&:hover': {
                  color: '#999',
                  borderColor: '#999',
                  backgroundColor: 'transparent',
                },
              }}
            >
              {translate(property?.is_archived ? 'unit.unarchiveButton' : 'unit.archiveButton')}
            </Button>

            <Button
              onClick={formik.submitForm}
              variant="contained"
              sx={{ color: 'white', marginLeft: 2 }}
              disabled={updateLoading || !formik.dirty}
            >
              {!updateLoading && translate('unit.saveButton')}
              {updateLoading && <Loader />}
            </Button>
          </Box>
        </InfoBox>

        {/* <Button
          variant="contained"
          sx={{ p: '11px 22px', color: 'white', width: '176px', height: '48px' }}
          onClick={formik.submitForm}
        >
          {translate('contact.saveButton')}
        </Button> */}
      </Box>
      <ListingsForm formik={formik} />
    </Page>
  );
};

export default EditListings;
