import { Autocomplete, debounce, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useLocales from '../hooks/useLocales';
import * as contactActions from '../models/contacts/actions';
import { selectors as contactSelectors } from '../models/contacts/reducers';
import SearchIcon from './Icons/SearchIcon';

const ContactSearchBox = ({ name, formik, filters }) => {
  const serializeOption = (opt) => `${opt.name} - ${opt.mobile_number}`;
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const search = useSelector(contactSelectors.search);
  const searchLoading = useSelector(contactSelectors.loading);

  const handleSearch = debounce((e) => {
    const input = e.target.value;
    dispatch(contactActions.searchContactByNameRequest({ input, filters }));
  }, 500);

  useEffect(() => {
    handleSearch({ target: { value: '' } });
  }, []);

  return (
    <Autocomplete
      options={search || []}
      loading={!!searchLoading}
      getOptionLabel={serializeOption}
      filterOptions={(x) => x}
      fullWidth
      onInputChange={handleSearch}
      onChange={(e, v) => {
        if (v) {
          formik.setFieldValue(name, v.id);
          formik.setFieldValue('name', v.name);
          formik.setFieldValue('email', v.email);
          formik.setFieldValue('mobile_number', v.mobile_number);
        }
      }}
      isOptionEqualToValue={(opt, value) => serializeOption(opt) === serializeOption(value)}
      renderInput={(params) => (
        <TextField
          name="tenant_contact_id"
          error={formik.touched[name] && formik.errors[name]}
          InputProps={{
            ...(params.InputProps || {}),
            startAdornment: <SearchIcon />,
            type: 'search',
          }}
          value={formik.values[name]}
          placeholder={translate('deal.searchLabel')}
          {...params}
        />
      )}
    />
  );
};

export default ContactSearchBox;
