import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import GetAppIcon from '@mui/icons-material/GetApp';
import PlaceIcon from '@mui/icons-material/Place';
import UndoIcon from '@mui/icons-material/Undo';
import {
  Avatar,
  Box,
  Card,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Link,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import download from 'downloadjs';
import { useFormik } from 'formik';
import { toPng } from 'html-to-image';
import QRCode from 'qrcode';
import { React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Sqids from 'sqids';

import NuzulLogo from '../../assets/logo/logo_full.svg';
import Iconify from '../../components/Iconify';
import { CLIENT_PORT, ENV } from '../../config';
import useLocales from '../../hooks/useLocales';
import * as teamActions from '../../models/team/actions';
import { selectors as userSelectors } from '../../models/user/reducers';

const MemberCardModal = ({ open, onClose, onSubmit, member, memberID, project }) => {
  const {
    translate,
    currentLang: { value: languageCode },
  } = useLocales();
  const dispatch = useDispatch();
  const workspace = useSelector(userSelectors.workspace);

  const clientDomain = workspace?.client_domain;

  const [dataURL, setDataURL] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [copied, setCopied] = useState(false);

  const sqids = new Sqids({
    alphabet: '0123456789',
  });
  const encodedMemberID = sqids.encode([memberID]);

  const protocol = ENV === 'local' ? 'http' : 'https';

  let url = '';

  if (ENV === 'local') {
    if (member?.short_link) {
      url = `${protocol}://${clientDomain}${CLIENT_PORT}${project ? `/projects/${project?.id}` : ''}?ref=${
        member?.short_link
      }-${encodedMemberID}${project ? '&standalone=true' : ''}`;
    } else {
      url = `${protocol}://${clientDomain}${CLIENT_PORT}${
        project ? `/projects/${project?.id}` : ''
      }?ref=${encodedMemberID}${project?.id ? '&standalone=true' : ''}`;
    }
  } else if (member?.short_link) {
    url = `${protocol}://${clientDomain}${project ? `/projects/${project?.id}` : ''}?ref=${
      member?.short_link
    }-${encodedMemberID}${project ? '&standalone=true' : ''}`;
  } else {
    url = `${protocol}://${clientDomain}${project ? `/projects/${project?.id}` : ''}?ref=${encodedMemberID}${
      project ? '&standalone=true' : ''
    }`;
  }

  const callback = (type) => {
    if (type === 'success') {
      toast(translate('member.editURLSuccessfully'), { type: 'success' });
      dispatch(teamActions.getMemberByIdRequest({ memberID }));
    } else {
      toast(translate('error'), { type: 'error' });
    }
  };

  const formik = useFormik({
    initialValues: {
      shortLink: member?.short_link || '',
    },
    onSubmit: (values) => {
      dispatch(teamActions.editShortLinkRequest({ ...values, callback }));
      onSubmit();
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    const generateQRCode = async () => {
      try {
        const qrDataURL = await QRCode.toDataURL(url, {
          width: 256,
          errorCorrectionLevel: 'H',
        });
        setDataURL(qrDataURL);
      } catch (error) {
        console.error('Error generating QR code:', error);
      }
    };

    generateQRCode();
  }, [url]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    formik.submitForm();
    setIsEditing(false);
  };

  const handleBackClick = () => {
    setIsEditing(false);
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(url);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const handleDownload = async () => {
    const node = document.getElementById('card-image');

    const options = {
      filter: (node) => {
        if (node.tagName === 'BUTTON' || node.tagName === 'A') {
          return false;
        }
        return true;
      },
    };

    try {
      const dataUrl = await toPng(node, options);

      const img = new Image();
      img.src = dataUrl;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = img.width;
        canvas.height = img.height;

        ctx.drawImage(img, 0, 0);

        const logo = new Image();
        logo.src = NuzulLogo;
        logo.onload = () => {
          const logoWidth = 120;
          const logoHeight = 45;

          const xPos = (canvas.width - logoWidth) / 2;
          const yPos = canvas.height - logoHeight - 20;

          ctx.drawImage(logo, xPos, yPos, logoWidth, logoHeight);

          const finalDataUrl = canvas.toDataURL('image/png');

          download(finalDataUrl, 'myCard.png');
        };
      };
    } catch (error) {
      console.error('Error generating image:', error);
    }
  };

  return (
    <Dialog open={open}>
      <div id="card-image" style={{ backgroundColor: 'white', borderRadius: 'inherit' }}>
        <Box sx={{ minHeight: 680, minWidth: 340, p: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <IconButton sx={{ p: 0 }} onClick={onClose}>
              <CloseIcon />
            </IconButton>
            <IconButton sx={{ p: 0 }} onClick={handleDownload}>
              <GetAppIcon />
            </IconButton>
          </Box>

          <Box display="flex" flexDirection="column" alignItems="center">
            <Avatar
              id="logo"
              src={workspace?.logo_url || null}
              alt={languageCode === 'en' ? workspace?.name_en : workspace?.name_ar}
              sx={{
                borderRadius: 3,
                width: 200,
                height: 200,
              }}
            >
              {!workspace?.logo_url && languageCode === 'en' ? workspace?.name_en[0] : workspace?.name_ar[0]}
            </Avatar>
          </Box>

          <Box display="flex" justifyContent="center" alignItems="center" sx={{ padding: 3, pb: 0 }}>
            <Card sx={{ border: 1, borderColor: '#C4CDD5' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Grid style={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar alt={member?.name} sx={{ margin: 1 }} />
                  <Typography sx={{ margin: 1, textAlign: 'center' }}>{member?.name}</Typography>
                  <Divider orientation="vertical" variant="middle" flexItem />
                </Grid>
                <Grid style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ margin: 1 }}>{member?.mobile_number}</Typography>
                </Grid>
              </Box>
            </Card>
          </Box>

          <Box display="flex" justifyContent="center" alignItems="center">
            <img src={dataURL} alt="QR code" />
          </Box>

          {!isEditing ? (
            <Box>
              {project ? (
                <Box display="flex" justifyContent="center" alignItems="center" sx={{}}>
                  <Card sx={{ border: 1, borderColor: '#C4CDD5' }}>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: 1,
                        textAlign: 'center',
                      }}
                    >
                      {`${translate('project.project')} ${
                        languageCode === 'ar' ? project?.name_ar : project?.name_en
                      } `}
                    </Typography>
                    {project?.district && (
                      <>
                        <Divider orientation="horizontal" variant="middle" sx={{ mx: 2 }} />
                        <Typography
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: 'gray',
                            margin: 1,
                          }}
                        >
                          <PlaceIcon />
                          {languageCode === 'ar'
                            ? `${project?.district?.name_ar}, ${project?.city?.name_ar}`
                            : `${project?.district?.name_en}, ${project?.city?.name_en}`}
                        </Typography>
                      </>
                    )}
                  </Card>
                </Box>
              ) : (
                <Box display="flex" justifyContent="center" alignItems="center" dir="ltr">
                  <Typography
                    component="a"
                    href={url}
                    target="_blank"
                    sx={{
                      color: '#212B36',
                      textAlign: 'center',
                      textDecoration: 'underline',
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    {url}
                  </Typography>
                </Box>
              )}

              <Box display="flex" justifyContent="center" alignItems="center" dir="rtl" sx={{ pt: 1 }}>
                {project ? (
                  <Tooltip
                    title={translate('project.navToProjectPage')}
                    onClose={() => setCopied(false)}
                    placement="right"
                  >
                    <Link href={url} target="_blank">
                      <IconButton>
                        <Iconify icon="icon-park-outline:share" />
                      </IconButton>
                    </Link>
                  </Tooltip>
                ) : (
                  <>
                    {!window.location.href.includes('team') && (
                      <Tooltip title="Edit URL" placement="right">
                        <IconButton aria-label="edit" onClick={handleEditClick}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </>
                )}
                <Tooltip title={copied ? 'Copied!' : 'Copy URL'} onClose={() => setCopied(false)} placement="left">
                  <IconButton aria-label="copy" onClick={handleCopyClick}>
                    <FileCopyIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          ) : (
            <Box>
              <Box display="flex" justifyContent="center" alignItems="center" dir="ltr">
                <Typography sx={{ color: '#212B36' }}>{`${url.split('=')[0]}=`}</Typography>
                <TextField
                  type="text"
                  name="shortLink"
                  value={formik.values.shortLink}
                  onChange={formik.handleChange}
                  variant="outlined"
                  size="small"
                  sx={{ width: '120px' }}
                />
                <Typography sx={{ color: '#212B36' }}>{`-${encodedMemberID}`}</Typography>
              </Box>

              <Box display="flex" justifyContent="center" alignItems="center" dir="rtl">
                <Tooltip title="Save" placement="right">
                  <IconButton aria-label="save" onClick={handleSaveClick}>
                    <CheckIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Back" placement="left">
                  <IconButton aria-label="back" onClick={handleBackClick}>
                    <UndoIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          )}
        </Box>
      </div>
    </Dialog>
  );
};

export default MemberCardModal;
