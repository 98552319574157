// @mui
import { Box, Button, Card, CardContent, Container, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
// hooks
import { useNavigate } from 'react-router-dom';

// components
import Page from '../../components/Page';
import QuickSearchToolbar from '../../components/QuickSearchToolbar';
import useLocales from '../../hooks/useLocales';
import useSettings from '../../hooks/useSettings';
import TServices from '../../models/t-services/services';
import AddServiceModal from './AddServiceModal';

// ----------------------------------------------------------------------

export default function ServicesView() {
  const { themeStretch } = useSettings();
  const { translate } = useLocales();
  const navigate = useNavigate();

  const [tServices, setTServices] = useState([]);
  const [showAddServiceModal, setShowAddServiceModal] = useState(false);

  const columns = [
    {
      field: 'id',
      headerName: '#',
      maxWidth: 100,
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'name_ar',
      headerName: translate('service.nameAr'),
      maxWidth: 380,
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'name_en',
      headerName: translate('service.nameEn'),
      maxWidth: 380,
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'base_price',
      headerName: translate('service.price'),
      headerClassName: 'datagrid-header',
      flex: true,
      align: 'center',
      headerAlign: 'center',
      valueGetter: ({ row }) =>
        row.is_free
          ? translate('service.isFree')
          : (
              (parseFloat(row.base_price) || 0) +
              (parseFloat(row.base_price) * (parseFloat(row.tax_percentage) / 100) || 0)
            ).toFixed(2),
    },
    {
      field: 'published_on_website',
      headerName: translate('service.publishedOnWebsite'),
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
      valueGetter: ({ row }) => (row.published_on_website ? translate('yes') : translate('no')),
    },
  ];

  const handleRow = (params) => {
    navigate(`/maktb/services/${params.row.id}`);
  };

  useEffect(() => {
    TServices.getServices().then((res) => {
      setTServices(res.data.data);
    });
  }, []);

  return (
    <Page title={translate('services')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <AddServiceModal open={showAddServiceModal} onClose={() => setShowAddServiceModal(false)} />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h3" component="h1">
              {translate('services')}
            </Typography>
          </Box>
          <Button
            variant="contained"
            sx={{ p: '11px 22px', color: 'white', width: '176px', height: '48px' }}
            onClick={() => setShowAddServiceModal(true)}
          >
            {translate('service.addService')}
          </Button>
        </Box>

        <Card>
          <CardContent sx={{ p: 0 }}>
            <Box>
              <DataGrid
                onRowClick={handleRow}
                loading={false}
                columns={columns}
                rows={tServices || []}
                disableColumnFilter
                disableColumnSelector
                components={{
                  Toolbar: QuickSearchToolbar,
                }}
                hideFooterSelectedRowCount
                disableSelectionOnClick
                rowsPerPageOptions={[10]}
                pageSize={10}
                autoHeight
                sx={{
                  '& .datagrid-header': {
                    backgroundColor: '#F4F6F8',
                  },
                  '& .MuiDataGrid-columnHeaders': {
                    borderRadius: 0,
                  },
                  border: 'none',
                }}
              />
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}
