import ClearIcon from '@mui/icons-material/Clear';
import Visibility from '@mui/icons-material/VisibilityOutlined';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  Container,
  debounce,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
// hooks
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
// @mui
import * as Yup from 'yup';

import ExportAsSheetButton from '../../components/buttons/ExportAsSheetButton';
import Iconify from '../../components/Iconify';
import SearchIcon from '../../components/Icons/SearchIcon';
// components
import Page from '../../components/Page';
import PropertyCard, { CustomRow, PublishedAndAvailablitiyBox } from '../../components/PropertyCard';
import useLocales from '../../hooks/useLocales';
import useSettings from '../../hooks/useSettings';
import * as propertiesActions from '../../models/properties/actions';
import { selectors as propertiesSelectors } from '../../models/properties/reducers';
import { downloadExcel } from '../../utils/domUtils';
import AddPropertyModal from '../deals/AddDealModal';

// ----------------------------------------------------------------------

const propertyCreationSchema = Yup.object({
  category: Yup.string().oneOf(['commercial', 'residential']).required('required'),
  purpose: Yup.string().oneOf(['rent', 'sell']).required('Required'),
  type: Yup.string()
    .oneOf([
      'villa',
      'tower_apartment',
      'building_apartment',
      'villa_apartment',
      'land',
      'duplex',
      'townhouse',
      'mansion',
      'villa_floor',
      'farm',
      'istraha',
      'store',
      'office',
      'storage',
      'building',
      'resort',
      'hotel',
      'compound',
      'showroom',
    ])
    .required('Required'),
});

export const propertiesColumns = (translate, languageCode) => [
  {
    field: 'unit_number',
    headerName: '#',
    maxWidth: 100,
    flex: true,
    headerClassName: 'datagrid-header',
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'type',
    headerName: translate('unit.type'),
    maxWidth: 280,
    flex: true,
    headerClassName: 'datagrid-header',
    headerAlign: 'center',
    align: 'center',
    valueGetter: ({ row }) => translate(`unit.${row.type}`),
  },
  {
    field: 'views',
    headerName: translate('unit.views'),
    maxWidth: 280,
    flex: true,
    headerClassName: 'datagrid-header',
    headerAlign: 'center',
    align: 'center',
    valueGetter: ({ row }) => row.views,
  },
  {
    field: 'purpose',
    headerName: translate('unit.purpose'),
    maxWidth: 180,
    flex: true,
    headerClassName: 'datagrid-header',
    headerAlign: 'center',
    align: 'center',
    valueGetter: ({ row }) => translate(`unit.${row.purpose}`),
  },
  {
    field: 'area',
    headerName: translate('unit.area'),
    headerClassName: 'datagrid-header',
    flex: true,
    align: 'center',
    headerAlign: 'center',
    valueGetter: ({ row }) => row.area || '-',
  },
  {
    field: 'district',
    headerName: `${translate('unit.city')} - ${translate('unit.district')}`,
    flex: true,
    headerClassName: 'datagrid-header',
    headerAlign: 'center',
    align: 'center',
    valueGetter({ row }) {
      const city = languageCode === 'ar' ? row.city?.name_ar : row.city?.name_en || '-';
      const district = languageCode === 'ar' ? row.district?.name_ar : row.district?.name_en || '-';

      return `${city ? `${city} - ` : ''}${district || ''}`;
    },
  },
  {
    field: 'created_by',
    headerName: translate('unit.createdBy'),
    flex: true,
    headerClassName: 'datagrid-header',
    headerAlign: 'center',
    align: 'center',
    valueGetter: ({ row }) => row.created_by || '-',
  },
  {
    field: 'sort',
    headerName: translate('unit.sort'),
    flex: true,
    headerClassName: 'datagrid-header',
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'published_on_website',
    headerName: translate('unit.publishedOnWebsite'),
    flex: true,
    headerClassName: 'datagrid-header',
    headerAlign: 'center',
    align: 'center',
    renderCell: (c) => <PublishedAndAvailablitiyBox property={c.row} translate={translate} />,
  },
];

const localStorageKey = `propertiesQueryParams.${localStorage.getItem('tenant_id')}`;

const normalizeQueryParams = (params) => {
  const normalizedParams = { ...params };
  ['category', 'type', 'purpose'].forEach((key) => {
    if (normalizedParams[key].length === 0) {
      delete normalizedParams[key];
    } else {
      normalizedParams[key] = normalizedParams[key].join(',');
    }
  });
  return normalizedParams;
};

export default function PropertiesView() {
  const { themeStretch } = useSettings();
  const {
    translate,
    currentLang: { value: languageCode },
  } = useLocales();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [listView, setListView] = useState(true);
  const [showAddPropertyModal, setShowAddPropertyModal] = useState(false);

  const queryParamsFromStorage = JSON.parse(localStorage.getItem(localStorageKey) || '{}');
  const [queryParams, setQueryParams] = useState({
    per_page: queryParamsFromStorage.per_page || 100,
    page: queryParamsFromStorage.page || 1,
    is_office: queryParamsFromStorage.is_office || true,
    include_archived: queryParamsFromStorage.include_archived || 0,
    category: queryParamsFromStorage.category || [],
    type: queryParamsFromStorage.type || [],
    purpose: queryParamsFromStorage.purpose || [],
    search: queryParamsFromStorage.search || null,
    sort_by: queryParamsFromStorage.sort_by || null,
    sort_order: queryParamsFromStorage.sort_order || null,
  });

  const onViewChange = () => {
    setListView((value) => !value);
  };

  const onSearch = (e) => {
    const { value } = e.target;
    setQueryParams({ ...queryParams, search: value });
  };

  useEffect(() => {
    dispatch(propertiesActions.getPropertiesRequest(normalizeQueryParams(queryParams)));
  }, [queryParams]);

  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(queryParams));
  }, [queryParams]);

  const properties = useSelector(propertiesSelectors.properties);
  const getPropertiesLoading = useSelector(propertiesSelectors.getPropertiesLoading);
  const totalProperties = useSelector(propertiesSelectors.totalProperties);

  const callback = (type, r) => {
    if (type === 'success') {
      navigate(`/maktb/properties/${r.data.id}`);
    } else {
      toast(translate(r?.response?.data?.message), { type: 'info' });
    }
  };

  const formik = useFormik({
    validationSchema: propertyCreationSchema,
    initialValues: {
      category: '',
      purpose: '',
      type: '',
      unit_number: '0',
      product: 'office',
    },
    onSubmit: (values) => {
      dispatch(propertiesActions.createPropertyRequest({ ...values, callback }));
      dispatch(propertiesActions.getPropertiesRequest(normalizeQueryParams(queryParams)));
      setShowAddPropertyModal(false);
    },
    validateOnMount: true,
  });

  const handleRow = (params) => {
    navigate(`/maktb/properties/${params.row.id}`);
  };

  const handlePageChange = (newPage) => {
    setQueryParams({ ...queryParams, page: newPage + 1 });
  };

  const handleSelect = (event, key) => {
    setQueryParams({
      ...queryParams,
      [key]: event.target.value,
    });
  };

  const handleUnselect = (key, value) => {
    setQueryParams({
      ...queryParams,
      [key]: queryParams[key].filter((item) => item !== value),
    });
  };

  const handleClearAll = (key) => {
    setQueryParams((prevParams) => ({
      ...prevParams,
      [key]: [],
    }));
  };

  const exportAsSheet = () => {
    const normalizedQueryParams = { ...queryParams };

    ['category', 'type', 'purpose'].forEach((key) => {
      if (normalizedQueryParams[key].length === 0) {
        delete normalizedQueryParams[key];
      } else {
        normalizedQueryParams[key] = normalizedQueryParams[key].join(',');
      }
    });

    dispatch(
      propertiesActions.exportPropertiesAsSheetRequest({
        ...normalizedQueryParams,
        callback: (type, res) => {
          if (type === 'success') {
            downloadExcel(res, 'properties');
          } else {
            toast.error('Failed to export properties as sheet');
          }
        },
      })
    );
  };

  const categories = [
    { value: 'commercial', text: translate('deal.commercial') },
    { value: 'residential', text: translate('deal.residential') },
  ];
  const types = [
    { value: 'tower_apartment', text: translate('deal.properties.tower_apartment') },
    { value: 'building_apartment', text: translate('deal.properties.building_apartment') },
    { value: 'villa_apartment', text: translate('deal.properties.villa_apartment') },
    { value: 'villa_floor', text: translate('deal.properties.villa_floor') },
    { value: 'townhouse', text: translate('deal.properties.townhouse') },
    { value: 'duplex', text: translate('deal.properties.duplex') },
    { value: 'villa', text: translate('deal.properties.villa') },
    { value: 'building', text: translate('deal.properties.building') },
    { value: 'mansion', text: translate('deal.properties.mansion') },
    { value: 'store', text: translate('deal.properties.store') },
    { value: 'office', text: translate('deal.properties.office') },
    { value: 'storage', text: translate('deal.properties.storage') },
    { value: 'land', text: translate('deal.properties.land') },
    { value: 'farm', text: translate('deal.properties.farm') },
    { value: 'istraha', text: translate('deal.properties.istraha') },
    { value: 'resort', text: translate('deal.properties.resort') },
    { value: 'hotel', text: translate('deal.properties.hotel') },
    { value: 'compound', text: translate('deal.properties.compound') },
    { value: 'showroom', text: translate('deal.properties.showroom') },
  ];
  const purposes = [
    { value: 'rent', text: translate('unit.rent') },
    { value: 'sell', text: translate('unit.sell') },
  ];

  const columns = propertiesColumns(translate, languageCode);

  const navigateToPropertyPage = (id) => {
    navigate(`/maktb/properties/${id}`);
  };

  const handleCheckboxChange = () => {
    setQueryParams({ ...queryParams, include_archived: !queryParams.include_archived === true ? 1 : 0 });
  };

  const handleSortModelChange = (sortModel) => {
    setQueryParams({
      ...queryParams,
      ...(sortModel.length > 0 && {
        sort_by: sortModel[0].field,
        sort_order: sortModel[0].sort,
      }),
      ...(sortModel.length === 0 && {
        sort_by: null,
        sort_order: null,
      }),
    });
  };

  return (
    <Page title={translate('units')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <AddPropertyModal
          open={showAddPropertyModal}
          formik={formik}
          onClose={() => setShowAddPropertyModal(false)}
          showSearchbar={false}
          isBuy={false}
          title={translate('unit.creationTitle')}
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h3" component="h1">
              {translate('units')}
            </Typography>

            <IconButton
              sx={{ p: 1, border: '1px solid rgba(145, 158, 171, 0.32)', borderRadius: '8px', ml: 2 }}
              onClick={onViewChange}
            >
              {listView ? <Iconify icon={'tabler:layout-cards'} /> : <Iconify icon={'tabler:table'} />}
            </IconButton>
          </Box>
          <Button
            variant="contained"
            sx={{ color: 'white', pt: 1, pb: 1 }}
            onClick={() => setShowAddPropertyModal(true)}
          >
            {translate('unit.createUnitButton')}
          </Button>
        </Box>

        {listView ? (
          <Card className="no-animation">
            <CardContent sx={{ p: 0 }}>
              <Box>
                <Box sx={{}}>
                  <FormControlLabel
                    sx={{ ml: 2, mt: 2 }}
                    label={translate('unit.includeArchived')}
                    control={<Checkbox name="include_archived" checked={Boolean(queryParams.include_archived)} />}
                    onChange={handleCheckboxChange}
                  />
                </Box>
                <Grid container spacing={2} px={3}>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel id="select-category">{translate('unit.category')}</InputLabel>
                      <Select
                        sx={{ height: '60px' }}
                        label={translate('unit.category')}
                        labelId="select-category"
                        multiple
                        value={queryParams.category}
                        onChange={(e) => handleSelect(e, 'category')}
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.2, height: '32px', overflowY: 'auto' }}>
                            {selected.map((value) => (
                              <Chip
                                sx={{ backgroundColor: '#FFC10722', color: '#7A4F01' }}
                                key={value}
                                label={translate(`deal.${value}`)}
                                onDelete={() => handleUnselect('category', value)}
                                onMouseDown={(event) => {
                                  event.stopPropagation();
                                }}
                              />
                            ))}
                            {selected.length > 0 && (
                              <Tooltip title={translate('unit.clearAll')}>
                                <IconButton
                                  sx={{ ml: 'auto' }}
                                  size="small"
                                  onClick={() => handleClearAll('category')}
                                  onMouseDown={(event) => {
                                    event.stopPropagation();
                                  }}
                                >
                                  <ClearIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Box>
                        )}
                      >
                        {categories.map((category) => (
                          <MenuItem key={category.value} value={category.value}>
                            <Checkbox checked={queryParams.category.indexOf(category.value) > -1} />
                            <ListItemText primary={category.text} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel id="select-type">{translate('unit.listingType')}</InputLabel>
                      <Select
                        sx={{ height: '60px' }}
                        label={translate('unit.listingType')}
                        labelId="select-type"
                        multiple
                        value={queryParams.type}
                        onChange={(e) => handleSelect(e, 'type')}
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.2, height: '32px', overflowY: 'auto' }}>
                            {selected.map((value) => (
                              <Chip
                                sx={{ backgroundColor: '#FFC10722', color: '#7A4F01' }}
                                key={value}
                                label={translate(`deal.properties.${value}`)}
                                onDelete={() => handleUnselect('type', value)}
                                onMouseDown={(event) => {
                                  event.stopPropagation();
                                }}
                              />
                            ))}
                            {selected.length > 0 && (
                              <Tooltip title={translate('unit.clearAll')}>
                                <IconButton
                                  sx={{ ml: 'auto' }}
                                  size="small"
                                  onClick={() => handleClearAll('type')}
                                  onMouseDown={(event) => {
                                    event.stopPropagation();
                                  }}
                                >
                                  <ClearIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Box>
                        )}
                      >
                        {types.map((type) => (
                          <MenuItem key={type.value} value={type.value}>
                            <Checkbox checked={queryParams.type.indexOf(type.value) > -1} />
                            <ListItemText primary={type.text} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel id="select-purpose">{translate('unit.purpose')}</InputLabel>
                      <Select
                        sx={{ height: '60px' }}
                        label={translate('unit.purpose')}
                        labelId="select-purpose"
                        multiple
                        value={queryParams.purpose}
                        onChange={(e) => handleSelect(e, 'purpose')}
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.2, height: '32px', overflowY: 'auto' }}>
                            {selected.map((value) => (
                              <Chip
                                sx={{ backgroundColor: '#FFC10722', color: '#7A4F01' }}
                                key={value}
                                label={translate(`unit.${value}`)}
                                onDelete={() => handleUnselect('purpose', value)}
                                onMouseDown={(event) => {
                                  event.stopPropagation();
                                }}
                              />
                            ))}
                            {selected.length > 0 && (
                              <Tooltip title={translate('unit.clearAll')}>
                                <IconButton
                                  sx={{ ml: 'auto' }}
                                  size="small"
                                  onClick={() => handleClearAll('purpose')}
                                  onMouseDown={(event) => {
                                    event.stopPropagation();
                                  }}
                                >
                                  <ClearIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Box>
                        )}
                      >
                        {purposes.map((purpose) => (
                          <MenuItem key={purpose.value} value={purpose.value}>
                            <Checkbox checked={queryParams.purpose.indexOf(purpose.value) > -1} />
                            <ListItemText primary={purpose.text} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Box sx={{ p: 3 }}>
                  <TextField
                    fullWidth
                    placeholder={translate('unit.searchLabel')}
                    InputProps={{
                      startAdornment: <SearchIcon />,
                    }}
                    defaultValue={queryParams.search}
                    onChange={debounce(onSearch, 200)}
                  />
                </Box>
                {properties?.length > 0 && (
                  <Box
                    sx={{
                      m: 2,
                      display: 'flex',
                      justifyContent: 'end',
                      alignItems: 'center',
                    }}
                  >
                    <ExportAsSheetButton count={totalProperties} onClick={exportAsSheet} />
                  </Box>
                )}

                <DataGrid
                  loading={getPropertiesLoading}
                  onRowClick={handleRow}
                  getRowClassName={(params) => `clickable ${params.row.is_archived ? 'archived' : ''}`}
                  columns={columns}
                  rows={properties || []}
                  disableColumnFilter
                  disableColumnSelector
                  hideFooterSelectedRowCount
                  disableSelectionOnClick
                  rowsPerPageOptions={[10]}
                  autoHeight
                  pagination
                  components={{
                    Row: CustomRow,
                  }}
                  page={queryParams.page - 1}
                  pageSize={queryParams.per_page}
                  rowCount={totalProperties || 0}
                  paginationMode="server"
                  onPageChange={handlePageChange}
                  sortingMode="server"
                  onSortModelChange={handleSortModelChange}
                  sortModel={
                    queryParams.sort_by && queryParams.sort_order
                      ? [{ field: queryParams.sort_by, sort: queryParams.sort_order }]
                      : []
                  }
                  sx={{
                    '& .datagrid-header': {
                      backgroundColor: '#F4F6F8',
                    },
                    '& .MuiDataGrid-columnHeaders': {
                      borderRadius: 0,
                    },
                    border: 'none',
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        ) : (
          <Grid container spacing={2}>
            {properties &&
              properties.map((p, i) => (
                <Grid item xs={12} md={6} lg={4} xl={3} key={i}>
                  <PropertyCard property={p}>
                    <Divider />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography
                        style={{ cursor: 'pointer', textDecoration: 'underline' }}
                        color="#000000"
                        onClick={() => navigateToPropertyPage(p.id)}
                      >
                        {translate(`deal.view`)}
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography style={{ paddingInlineEnd: '3px' }} color="#000000">
                          {p.views}
                        </Typography>
                        <Visibility fontSize="small" />
                      </Box>
                    </Box>
                  </PropertyCard>
                </Grid>
              ))}
          </Grid>
        )}
      </Container>
    </Page>
  );
}
