import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { alpha, Box, Card, CardContent, darken, IconButton, Link, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { GridRow } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import useLocales from '../hooks/useLocales';
import useSettings from '../hooks/useSettings';
import Iconify from './Iconify';
import BathIcon from './Icons/BathIcon';
import BedIcon from './Icons/BedIcon';

const PropertyCard = ({
  property,
  row = false,
  showLink = false,
  linkPath = '/maktb/properties',
  children,
  cardHeight = '300px',
  clickable = false,
  condensed = false,
  condensedOpacity = 0.3,
  selected,
  onClick,
}) => {
  const {
    currentLang: { value: languageCode },
  } = useLocales();
  const { themeDirection } = useSettings();
  const isArabic = themeDirection === 'rtl';

  const theme = useTheme();

  const districtName = languageCode === 'ar' ? property.district?.name_ar : property.district?.name_en;
  const cityName = languageCode === 'ar' ? property.city?.name_ar : property.city?.name_en;
  const countryName = languageCode === 'ar' ? property.country?.name_ar : property.country?.name_en;
  const { translate } = useLocales();

  const navigate = useNavigate();

  return (
    <Card
      sx={{
        border: ` ${selected && clickable ? `1px solid ${theme.palette.primary.main}` : ''}`,
        cursor: clickable ? 'pointer' : 'default',
        '&:hover': clickable && {
          border: `1px solid ${theme.palette.primary.main}`,
        },
      }}
      onClick={clickable ? onClick : undefined}
    >
      <CardContent
        sx={{
          p: 0,
          '&:last-child': {
            paddingBottom: 0,
          },
        }}
      >
        <Box sx={{ display: 'flex', position: 'relative', flexDirection: row ? 'row' : 'column' }}>
          {showLink && (
            <Link
              href={`${linkPath}/${property.id}`}
              style={{
                position: 'absolute',
                top: 0,
                ...(isArabic
                  ? {
                      left: 0,
                    }
                  : {
                      right: 0,
                    }),
              }}
            >
              <Tooltip title={translate('unit.navigateToUnit')}>
                <IconButton variant="outlined" color="secondary">
                  <Iconify icon="icon-park-outline:share" />
                </IconButton>
              </Tooltip>
            </Link>
          )}
          <div
            className="image-container"
            style={{
              backgroundImage: property.cover_image_url
                ? `url(${property.cover_image_url})`
                : 'url(/assets/placeholder.svg)',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: cardHeight,
              ...(condensed && {
                position: 'absolute',
                inset: 0,
                zIndex: -1,
                opacity: condensedOpacity,
                height: '100%',
                width: '100%',
                filter: 'blur(1px)',
                maskImage: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 100%)',
              }),
            }}
          />

          <Box sx={{ p: 2, width: '100%' }}>
            <Typography variant="subtitle1">
              {translate(`deal.properties.${property.type}`)} {translate(`for.${property.purpose}`)}
            </Typography>

            <Typography variant="caption">
              {translate('unit.unit_number')}: {property.unit_number}
            </Typography>

            {!districtName && (
              <Typography paragraph variant="body1" sx={{ fontSize: '1rem' }}>
                -
              </Typography>
            )}

            {districtName && cityName && countryName && (
              <Typography paragraph variant="body1" sx={{ fontSize: '1rem' }}>
                {districtName}, {cityName}, {countryName}
              </Typography>
            )}

            <Box sx={{ display: 'flex' }}>
              <Box sx={{ display: 'flex' }}>
                {[
                  'villa',
                  'building_apartment',
                  'villa_apartment',
                  'duplex',
                  'townhouse',
                  'mansion',
                  'villa_floor',
                  'tower_apartment',
                ].find((t) => t === property.type) && (
                  <>
                    <BedIcon />
                    <Typography paragraph>{property.bedrooms}</Typography>
                    <Typography paragraph sx={{ ml: 0.5 }}>
                      {translate(`unit.bedrooms`)}
                    </Typography>
                  </>
                )}
              </Box>

              <Box sx={{ display: 'flex' }}>
                {[
                  'villa',
                  'building_apartment',
                  'villa_apartment',
                  'duplex',
                  'townhouse',
                  'mansion',
                  'villa_floor',
                  'tower_apartment',
                ].find((t) => t === property.type) && (
                  <>
                    <BathIcon />
                    <Typography paragraph>{property.bathrooms}</Typography>
                    <Typography paragraph sx={{ ml: 0.5 }}>
                      {translate(`unit.bathrooms`)}
                    </Typography>
                  </>
                )}
              </Box>
            </Box>

            {children}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

PropertyCard.propTypes = {
  property: PropTypes.object.isRequired,
};

const CustomRow = (props) => {
  const { row } = props;

  return (
    <Tooltip
      placement="top"
      arrow
      followCursor
      title={
        <Box
          sx={{
            opacity: 0.5,
          }}
        >
          <PropertyCard property={row} condensed condensedOpacity={1.0} />
        </Box>
      }
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: (theme) => alpha(theme.palette.background.paper, 0.7),
            boxShadow: (theme) => theme.shadows[25],
            p: 1,
            borderRadius: 3,
          },
        },
        arrow: {
          sx: {
            color: 'white',
            boxShadow: (theme) => theme.shadows[25],
          },
        },
      }}
    >
      <GridRow {...props} />
    </Tooltip>
  );
};

export const getAvailablityStatusLabel = (translate, status, purpose) => {
  const labels = {
    unavailable: translate('unit.unavailable'),
    available: translate('unit.available'),
    reserved: translate('unit.reserved'),
    sold: purpose === 'sell' ? translate('unit.sold') : null,
    rented: purpose === 'rent' ? translate('unit.rented') : null,
    soon: translate('unit.soon'),
  };

  return labels[status] || null;
};

const PublishedAndAvailablitiyBox = ({ property, translate }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
    }}
  >
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {property.product !== 'siyaha' && (
        <Typography
          variant="caption"
          sx={{
            color: (theme) =>
              `${
                property.availability_status === 'available'
                  ? darken(theme.palette.success.main, 0.2)
                  : darken(theme.palette.secondary.main, 0.2)
              } !important`,
            fontSize: 14,
          }}
        >
          {getAvailablityStatusLabel(translate, property.availability_status, property.purpose)}
        </Typography>
      )}
    </Box>
    {property.published_on_website ? (
      <CheckCircleOutlineOutlinedIcon
        sx={{
          color: (theme) => `${darken(theme.palette.success.main, 0.5)} !important`,
        }}
      />
    ) : (
      <CancelOutlinedIcon
        sx={{
          color: (theme) => `${darken(theme.palette.secondary.main, 0.5)} !important`,
        }}
      />
    )}
  </Box>
);

export { CustomRow, PublishedAndAvailablitiyBox };

export default PropertyCard;
