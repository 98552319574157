import { createAction } from '@reduxjs/toolkit';

export const getReservationsRequest = createAction('GET_RESERVATIONS_REQUEST');
export const getReservationsSuccess = createAction('GET_RESERVATIONS_SUCCESS');
export const getReservationsFailure = createAction('GET_RESERVATIONS_FAILURE');

export const getReservationDetailsRequest = createAction('GET_RESERVATION_DETAILS_REQUEST');
export const getReservationDetailsSuccess = createAction('GET_RESERVATION_DETAILS_SUCCESS');
export const getReservationDetailsFailure = createAction('GET_RESERVATION_DETAILS_FAILURE');

export const createReservationRequest = createAction('CREATE_RESERVATION_REQUEST');
export const createReservationSuccess = createAction('CREATE_RESERVATION_SUCCESS');
export const createReservationFailure = createAction('CREATE_RESERVATION_FAILURE');
