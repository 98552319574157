import { Box, Button, Divider, FormControl, FormHelperText, InputLabel, OutlinedInput } from '@mui/material';

import useLocales from '../hooks/useLocales';
import { cleanAndFormatNumber, fNumber } from '../utils/formatNumber';

const commonStyles = {
  p: 0,
  height: 1,
  border: '1px solid #919eab52',
  borderRadius: 0,
};

const topButtonStyles = {
  borderBottom: 'none',
  borderLeft: 'none',
  borderTopRightRadius: '8px',
};

const bottomButtonStyles = {
  borderTop: 'none',
  borderLeft: 'none',
  borderBottomRightRadius: '8px',
};

const NumberInput = ({
  name,
  helperText,
  label,
  sx,
  showControls = true,
  flipControls = false,
  positive = true,
  fullWidth = false,
  formik,
  required = false,
  ...props
}) => {
  const { translate } = useLocales();

  if (positive && formik.values[name] < 0) {
    throw new Error(`Can't pass a defaultValue of ${formik.values[name]} while positive is true`);
  }

  if (!showControls && flipControls) {
    throw new Error("Can't set flipControls when showControls is set to false");
  }

  const handleChange = (e) => {
    const value = cleanAndFormatNumber(e.target.value, positive);

    e.target.value = value;

    formik.setFieldValue(name, value);

    formik.handleChange(e);
  };

  const increment = () => {
    const currentValue = formik.values[name];
    formik.setFieldValue(name, (parseInt(currentValue, 10) || 0) + 1);
  };

  const decrement = () => {
    const currentValue = formik.values[name];

    if (positive && currentValue <= 0) return;

    formik.setFieldValue(name, (parseInt(currentValue, 10) || 0) - 1);
  };

  return (
    <FormControl
      required={required}
      sx={{
        ...(showControls
          ? {
              '& fieldset': {
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              },
            }
          : {}),
        borderRadius: 1,
        ...sx,
      }}
      fullWidth={fullWidth}
    >
      <InputLabel>{translate(label)}</InputLabel>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', height: 'auto' }}>
        <OutlinedInput
          name={name}
          type="text"
          inputProps={{
            inputMode: 'numeric',
          }}
          aria-describedby={helperText}
          label={translate(label)}
          fullWidth={fullWidth}
          onChange={handleChange}
          onKeyDown={(e) => {
            if (e.key === 'ArrowUp') {
              e.preventDefault();
              increment();
            } else if (e.key === 'ArrowDown') {
              e.preventDefault();
              decrement();
            }
          }}
          value={(formik.values[name] || '0').toString() === '0' ? '' : fNumber(formik.values[name])}
          error={formik.touched[name] && !!formik.errors[name]}
          {...props}
        />
        {showControls && (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {flipControls ? (
              <>
                <Button
                  sx={{
                    ...commonStyles,
                    ...topButtonStyles,
                    color: '#161C24',
                  }}
                  onClick={increment}
                >
                  +
                </Button>
                <Divider />
                <Button
                  sx={{
                    ...commonStyles,
                    ...bottomButtonStyles,
                    color: '#919EAB',
                  }}
                  onClick={decrement}
                >
                  -
                </Button>
              </>
            ) : (
              <>
                <Button
                  sx={{
                    ...commonStyles,
                    ...topButtonStyles,
                    color: '#161C24',
                  }}
                  onClick={increment}
                >
                  +
                </Button>
                <Divider />
                <Button
                  sx={{
                    ...commonStyles,
                    ...bottomButtonStyles,
                    color: positive && formik.values[name] <= 0 ? '#919EAB' : '#161C24',
                  }}
                  onClick={decrement}
                >
                  -
                </Button>
              </>
            )}
          </Box>
        )}
      </Box>

      <FormHelperText id="my-helper-text">{helperText}</FormHelperText>
    </FormControl>
  );
};
export default NumberInput;
