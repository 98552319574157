import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

const initialState = {
  loading: false,
  services: null,
  service: null,
};

const reducer = createReducer(initialState, {
  [actions.getServicesRequest]: (state) => {
    state.loading = true;
  },
  [actions.getServicesSuccess]: (state, action) => {
    state.loading = false;
    state.services = action.payload.data;
  },
  [actions.getServicesFailure]: (state) => {
    state.loading = false;
  },
  [actions.editServiceRequest]: (state) => {
    state.loading = true;
  },
  [actions.editServiceSuccess]: (state, action) => {
    state.loading = false;
    state.service = action.payload.data;
  },
  [actions.editServiceFailure]: (state) => {
    state.loading = false;
  },
  [actions.createServiceRequest]: (state) => {
    state.loading = true;
  },
  [actions.createServiceFailure]: (state) => {
    state.loading = false;
  },
  [actions.createServiceSuccess]: (state, action) => {
    state.loading = false;
    state.service = action.payload.data;
  },
  [actions.getServiceRequest]: (state) => {
    state.loading = true;
  },
  [actions.getServiceFailure]: (state) => {
    state.loading = false;
  },
  [actions.getServiceSuccess]: (state, action) => {
    state.loading = false;
    state.service = action.payload.data;
  },
});

const loading = (state) => state.services.loading;
const services = (state) => state.services.services;
const service = (state) => state.services.service;

const selectors = {
  loading,
  service,
  services,
};

export { initialState, reducer, selectors };
export default reducer;
