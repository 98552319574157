// @mui
import { Box, Button, Card, CardContent, Container, IconButton, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Iconify from '../../../components/Iconify';
// components
import Page from '../../../components/Page';
import PropertyCard from '../../../components/PropertyCard';
import QuickSearchToolbar from '../../../components/QuickSearchToolbar';
import useLocales from '../../../hooks/useLocales';
import useSettings from '../../../hooks/useSettings';
import { selectors as notificationsSelectors } from '../../../models/notifications/reducers';
import { hasPropertyReservationNotification } from '../../../models/notifications/utils';
import * as reservationsActions from '../../../models/t-reservations/actions';
import { selectors as reservationsSelectors } from '../../../models/t-reservations/reducers';
import AddReservationModal from './AddReservationModal';

// ----------------------------------------------------------------------

export const columns = (translate) => [
  {
    field: 'id',
    headerName: '#',
    maxWidth: 100,
    flex: true,
    headerClassName: 'datagrid-header',
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'guest_name',
    headerName: translate('siyaha.reservation.guestName'),
    maxWidth: 280,
    flex: true,
    headerClassName: 'datagrid-header',
    headerAlign: 'center',
    align: 'center',
    valueGetter: (params) => params.row?.tenant_contact?.name,
  },
  {
    field: 'unit',
    headerName: translate('siyaha.reservation.unitNumber'),
    maxWidth: 280,
    flex: true,
    headerClassName: 'datagrid-header',
    headerAlign: 'center',
    align: 'center',
    renderCell: ({ row }) =>
      row.property?.id ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <Tooltip
            placement="left"
            arrow
            title={<PropertyCard linkPath="/siyaha/listings" showLink property={row.property} condensed />}
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'white',
                  shadow: (theme) => theme.shadows[25],
                  p: 1,
                  borderRadius: 3,
                },
              },
              arrow: {
                sx: {
                  color: 'white',
                  shadow: (theme) => theme.shadows[25],
                },
              },
            }}
          >
            <Typography variant="body2">{row.property?.unit_number}</Typography>
          </Tooltip>
        </Box>
      ) : (
        '-'
      ),
  },
  {
    field: 'check_in_date',
    headerName: translate('siyaha.reservation.checkInDate'),
    flex: true,
    headerClassName: 'datagrid-header',
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'check_out_date',
    headerName: translate('siyaha.reservation.checkOutDate'),
    flex: true,
    headerClassName: 'datagrid-header',
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'status',
    headerName: translate('siyaha.reservation.status'),
    flex: true,
    headerClassName: 'datagrid-header',
    headerAlign: 'center',
    align: 'center',
    valueGetter: (params) => translate(`siyaha.reservation.statues.${params.row.status}`),
  },
  {
    field: 'paymentStatus',
    headerName: translate('siyaha.reservation.paymentStatus'),
    flex: true,
    headerClassName: 'datagrid-header',
    headerAlign: 'center',
    align: 'center',
    valueGetter: (params) => translate(`siyaha.reservation.paymentStatuses.${params.row.order.status}`),
  },
];

export default function ReservationsView() {
  const { themeStretch } = useSettings();
  const {
    translate,
    currentLang: { value: languageCode },
  } = useLocales();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [queryParams, setQueryParams] = useState({ include_archived: 0 });
  const [showAddReservationModal, setShowAddReservationModal] = useState(false);

  useEffect(() => {
    dispatch(reservationsActions.getReservationsRequest(queryParams));
  }, []);

  const reservations = useSelector(reservationsSelectors.reservations);
  const getReservationsLoading = useSelector(reservationsSelectors.getReservationsLoading);
  const notifications = useSelector(notificationsSelectors.notifications);

  const handleRow = (params) => {
    navigate(`/siyaha/reservations/${params.row.id}`);
  };

  const isUnseen = (id) => hasPropertyReservationNotification(id, notifications || []);

  return (
    <Page title={translate('siyaha.reservations')}>
      <AddReservationModal open={showAddReservationModal} onClose={() => setShowAddReservationModal(false)} />
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h3" component="h1">
              {translate('siyaha.reservations')}
            </Typography>
            <IconButton
              sx={{ p: 1, border: '1px solid rgba(145, 158, 171, 0.32)', borderRadius: '8px', ml: 2 }}
              onClick={() => {
                navigate('/siyaha/reservations/calendar');
              }}
            >
              <Iconify icon={'tabler:calendar'} />
            </IconButton>
          </Box>
          <Button
            variant="contained"
            sx={{ color: 'white', pt: 1, pb: 1 }}
            onClick={() => setShowAddReservationModal(true)}
          >
            {translate('siyaha.reservation.createReservation')}
          </Button>
        </Box>
        <Card className="no-animation">
          <CardContent sx={{ p: 0 }}>
            <Box>
              <DataGrid
                loading={getReservationsLoading}
                onRowClick={handleRow}
                getRowClassName={(params) => `clickable ${isUnseen(params.row.id) ? 'unread' : ''}`}
                columns={columns(translate)}
                rows={reservations || []}
                disableColumnFilter
                disableColumnSelector
                components={{
                  Toolbar: QuickSearchToolbar,
                }}
                hideFooterSelectedRowCount
                disableSelectionOnClick
                rowsPerPageOptions={[10]}
                pageSize={10}
                autoHeight
                sx={{
                  '& .datagrid-header': {
                    backgroundColor: '#F4F6F8',
                  },
                  '& .MuiDataGrid-columnHeaders': {
                    borderRadius: 0,
                  },
                  border: 'none',
                }}
              />
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}
