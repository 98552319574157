import { Navigate, useRoutes } from 'react-router-dom';

// layouts
import DashboardLayout from '../layouts/dashboard';
import UserLayout from '../layouts/user';
import CompleteAccount from '../pages/auth/completeAccount';
import Login from '../pages/auth/login';
import Registration from '../pages/auth/registration';
import ResetPassword from '../pages/auth/resetPassword/ResetPassword';
// pages
import VerifyCode from '../pages/auth/verifyCode/VerifyCode';
import AddContact from '../pages/contact/AddContact';
import ContactsList from '../pages/contact/ContactsList';
import EditContact from '../pages/contact/EditContact';
import DealsView from '../pages/deals';
import EditDeal from '../pages/deals/EditDeal';
import Dashboard from '../pages/maktb/Dashboard';
import EditOrder from '../pages/maktb/orders/EditOrder';
import OrdersView from '../pages/maktb/orders/OrdersView';
import SalesView from '../pages/maktb/sales';
import Marketing from '../pages/marketing';
import SMSCampaigns from '../pages/marketing/SMSCampaigns';
import NotificationsView from '../pages/notifications';
import NotFound from '../pages/Page404';
import Activations from '../pages/pay/activations/Activations';
import DashboardPay from '../pages/pay/Dashboard';
import DepositsView from '../pages/pay/deposits';
import TransactionsView from '../pages/pay/transactions';
import EditProject from '../pages/projects/EditProject';
import ProjectsView from '../pages/projects/ProjectsView';
import PropertiesView from '../pages/properties';
import EditProperty from '../pages/properties/EditProperty';
import RequestsView from '../pages/requests';
import AddRequest from '../pages/requests/AddRequest';
import EditRequest from '../pages/requests/EditRequest';
import ServicesView from '../pages/services';
import EditService from '../pages/services/EditService';
import Settings from '../pages/settings';
import Account from '../pages/settings/account';
import Payments from '../pages/settings/payments';
import Subscriptions from '../pages/settings/subscriptions';
import DashboardSiyaha from '../pages/siyaha/Dashboard';
import AddGuest from '../pages/siyaha/guests/AddGuest';
import EditGuest from '../pages/siyaha/guests/EditGuest';
import GuestsList from '../pages/siyaha/guests/GuestsList';
import ListingsView from '../pages/siyaha/listings';
import EditListings from '../pages/siyaha/listings/EditListings';
import ReservationsView from '../pages/siyaha/reservations';
import CalendarView from '../pages/siyaha/reservations/CalendarView';
import EditReservations from '../pages/siyaha/reservations/EditReservations';
import Checkout from '../pages/subscriptions/checkout';
import PaymentDetails from '../pages/subscriptions/PaymentDetails';
import PaymentForm from '../pages/subscriptions/PaymentForm';
import Upgrade from '../pages/subscriptions/upgrade';
import Team from '../pages/team';
import MemberDashboard from '../pages/team/MemberDashboard';
import Expired from '../pages/user/Expired';
import Invitations from '../pages/user/invitations/Invitations';
import Profile from '../pages/user/Profile';
import Workspaces from '../pages/user/workspaces/Workspaces';
import Website from '../pages/website';
import DomainSettings from '../pages/website/domainSettings/DomainSettings';
import IdentitySettings from '../pages/website/identitySettings';
import MapSettings from '../pages/website/map/MapSettings';
import Requests from '../pages/website/requests';
import SocialMediaLinks from '../pages/website/socialMediaLinks';
import WebsitePages from '../pages/website/websitePages';
import Privacy from '../pages/website/websitePages/Privacy';
import Terms from '../pages/website/websitePages/Terms';
import Workspace from '../pages/workspace/Workspace';
import { TENANT_ROLES } from '../utils/constants';
import { getSelectedProduct } from '../utils/location';
// components
import { ProtectedRoute } from './ProtectedRoute';
import RolesBasedRerouting from './RolesBasedRerouting';

// ----------------------------------------------------------------------

export default function Router() {
  /* eslint-disable no-restricted-globals */
  const selectedProduct = getSelectedProduct();

  const tenantRole = parseInt(localStorage.getItem('tenant_role'), 10);
  let homeUrl = '/login';
  if (selectedProduct === 'maktb') {
    homeUrl = tenantRole === TENANT_ROLES.companyMember ? '/member/dashboard' : '/maktb/dashboard';
  }
  if (selectedProduct === 'siyaha') {
    homeUrl = tenantRole === TENANT_ROLES.companyMember ? '/siyaha/crm/guests' : '/siyaha/dashboard';
  }
  if (selectedProduct === 'pay') {
    homeUrl = '/pay/dashboard';
  }
  return useRoutes([
    {
      path: '/',
      element: <Navigate to={homeUrl} replace />,
    },
    {
      path: '/registration',
      element: <Registration />,
    },
    {
      path: '/verify-code',
      element: <VerifyCode />,
    },
    {
      path: '/verify-mobile',
      element: <Registration isReset />,
    },
    {
      path: '/reset-password',
      element: <ResetPassword />,
    },
    {
      path: '/complete-account',
      element: <CompleteAccount />,
    },
    {
      path: '/login',
      element: <Login />,
    },

    // pricing starts
    {
      path: '/pricing',
      element: <UserLayout />,
      children: [
        {
          path: '',
          element: (
            <ProtectedRoute>
              <Upgrade />
            </ProtectedRoute>
          ),
        },
      ],
    },
    // pricing ends

    // cart starts
    {
      path: '/cart',
      element: <UserLayout />,
      children: [
        {
          path: 'siyaha',
          element: <ProtectedRoute>{<Checkout />}</ProtectedRoute>,
        },
        {
          path: 'maktb',
          element: <ProtectedRoute>{<Checkout />}</ProtectedRoute>,
        },
        {
          element: <Navigate to="/cart/payment" replace />,
        },
        {
          path: 'payment/:id',
          element: <ProtectedRoute>{<PaymentForm />}</ProtectedRoute>,
        },
        {
          element: <Navigate to="/cart/summery" replace />,
        },
        {
          path: 'summery',
          element: <ProtectedRoute>{/* <PaymentDetails /> */}</ProtectedRoute>,
        },
        {
          element: <Navigate to="payment-details" replace />,
        },
        {
          path: 'payment-details',
          element: <ProtectedRoute>{/* <PaymentDetails /> */}</ProtectedRoute>,
        },
      ],
    },
    // cart ends

    // orders starts
    {
      path: '/orders',
      element: <UserLayout />,
      children: [
        {
          element: <Navigate to="/orders/:id/payment" replace />,
        },
        {
          path: ':id/payment',
          element: <ProtectedRoute>{<PaymentForm />}</ProtectedRoute>,
        },
        {
          element: <Navigate to="/orders/:id/payment-summery" replace />,
        },
        {
          path: ':id/payment-summery',
          element: <ProtectedRoute>{<PaymentDetails />}</ProtectedRoute>,
        },
      ],
    },
    // orders ends

    // user starts
    {
      path: '/user',
      element: <UserLayout />,
      children: [
        {
          element: <Navigate to="/user/profile" replace />,
        },
        {
          path: 'profile',
          element: (
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          ),
        },
        {
          element: <Navigate to="/user/workspaces" replace />,
        },
        {
          path: 'workspaces',
          element: (
            <ProtectedRoute>
              <Workspaces />
            </ProtectedRoute>
          ),
        },
        {
          path: 'workspaces/:id/edit',
          element: <Workspace />,
        },
        {
          element: <Navigate to="/user/invitations" replace />,
        },
        {
          path: 'invitations',
          element: (
            <ProtectedRoute>
              <Invitations />
            </ProtectedRoute>
          ),
        },
      ],
    },
    // user ends

    // Siyaha

    // dashboard starts
    {
      path: '/maktb/dashboard',
      element: (
        <ProtectedRoute>
          <RolesBasedRerouting
            allowedRoles={[TENANT_ROLES.companyOwner, TENANT_ROLES.companyManager, TENANT_ROLES.companyAssociate]}
            homeUrl={homeUrl}
            tenantRole={tenantRole}
          >
            <DashboardLayout />
          </RolesBasedRerouting>
        </ProtectedRoute>
      ),
      children: [
        {
          element: <Dashboard />,
          index: true,
        },
      ],
    },

    {
      path: '/notifications',
      element: (
        <ProtectedRoute>
          <DashboardLayout />
        </ProtectedRoute>
      ),
      children: [
        {
          element: <NotificationsView />,
          index: true,
        },
      ],
    },

    {
      path: '/siyaha/dashboard',
      element: (
        <ProtectedRoute>
          <DashboardLayout />
        </ProtectedRoute>
      ),
      children: [
        {
          element: <DashboardSiyaha />,
          index: true,
        },
      ],
    },

    // listings starts
    {
      path: '/siyaha/listings',
      element: (
        <ProtectedRoute>
          <DashboardLayout />
        </ProtectedRoute>
      ),
      children: [
        {
          element: <ListingsView />,
          index: true,
        },
        {
          path: '/siyaha/listings/:id',
          element: <EditListings />,
        },
      ],
    },
    // listings ends

    // reservations starts
    {
      path: '/siyaha/reservations',
      element: (
        <ProtectedRoute>
          <DashboardLayout />
        </ProtectedRoute>
      ),
      children: [
        {
          path: '/siyaha/reservations/calendar',
          element: <CalendarView />,
        },
        {
          element: <ReservationsView />,
          index: true,
        },
        {
          path: '/siyaha/reservations/:id',
          element: <EditReservations />,
        },
      ],
    },
    // reservations ends

    // crm starts
    {
      path: '/siyaha/crm',
      element: (
        <ProtectedRoute>
          <DashboardLayout />
        </ProtectedRoute>
      ),
      children: [
        {
          element: <Navigate to="/siyaha/crm/guests" replace />,
        },
        {
          path: 'guests',
          element: <GuestsList />,
        },
        {
          path: 'guests/add',
          element: <AddGuest />,
        },
        {
          path: 'guests/:id',
          element: <EditGuest />,
        },
      ],
    },
    // crm ends

    // Pay
    {
      path: '/pay',
      element: (
        <ProtectedRoute>
          <DashboardLayout />
        </ProtectedRoute>
      ),
      children: [
        {
          path: '/pay/dashboard',
          element: <DashboardPay />,
          index: true,
        },
        {
          path: '/pay/activations',
          element: <Activations />,
        },
        {
          path: '/pay/transactions',
          element: <TransactionsView />,
        },
        {
          path: '/pay/deposits',
          element: <DepositsView />,
        },
      ],
    },

    // dashboard ends

    // team starts
    {
      path: '/team',
      element: (
        <ProtectedRoute>
          <RolesBasedRerouting allowedRoles={[TENANT_ROLES.companyOwner]} homeUrl={homeUrl} tenantRole={tenantRole}>
            <DashboardLayout />
          </RolesBasedRerouting>
        </ProtectedRoute>
      ),
      children: [
        {
          element: <Team />,
          index: true,
        },
        {
          path: '/team/:id/dashboard',
          element: <MemberDashboard />,
        },
      ],
    },
    // team ends

    {
      path: '/member',
      element: (
        <ProtectedRoute>
          <RolesBasedRerouting
            allowedRoles={[
              TENANT_ROLES.companyOwner,
              TENANT_ROLES.companyManager,
              TENANT_ROLES.companyAssociate,
              TENANT_ROLES.companyMember,
            ]}
            homeUrl={homeUrl}
            tenantRole={tenantRole}
          >
            <DashboardLayout />
          </RolesBasedRerouting>
        </ProtectedRoute>
      ),
      children: [
        {
          path: '/member/dashboard',
          element: <MemberDashboard />,
        },
      ],
    },

    // projects starts
    {
      path: '/maktb/projects',
      element: (
        <ProtectedRoute>
          <DashboardLayout />
        </ProtectedRoute>
      ),
      children: [
        {
          element: <ProjectsView />,
          index: true,
        },
        {
          path: '/maktb/projects/:id',
          element: <EditProject />,
        },
      ],
    },
    // projects ends

    // website starts
    {
      path: '/website',
      element: (
        <ProtectedRoute>
          <RolesBasedRerouting
            allowedRoles={[TENANT_ROLES.companyOwner, TENANT_ROLES.companyManager]}
            homeUrl={homeUrl}
            tenantRole={tenantRole}
          >
            <DashboardLayout />
          </RolesBasedRerouting>
        </ProtectedRoute>
      ),

      children: [
        {
          element: <Website />,
          index: true,
        },
        {
          path: '/website/domain-settings',
          element: <DomainSettings />,
        },
        {
          path: '/website/social-media-links',
          element: <SocialMediaLinks />,
        },
        {
          path: '/website/identity-settings',
          element: <IdentitySettings />,
        },
        {
          path: '/website/website-pages',
          element: <WebsitePages />,
        },
        {
          path: '/website/website-pages/privacy-policy',
          element: <Privacy />,
        },
        {
          path: '/website/website-pages/terms-conditions',
          element: <Terms />,
        },
        {
          path: '/website/requests',
          element: <Requests />,
        },
        {
          path: '/website/map-settings',
          element: <MapSettings />,
        },
      ],
    },
    // website ends

    // marketing starts
    {
      path: '/marketing',
      element: (
        <ProtectedRoute>
          <DashboardLayout />
        </ProtectedRoute>
      ),
      children: [
        {
          element: <Marketing />,
          index: true,
        },
        {
          path: '/marketing/sms-campaigns',
          element: <SMSCampaigns />,
        },
      ],
    },
    // marketing ends

    // properties starts
    {
      path: '/maktb/properties',
      element: (
        <ProtectedRoute>
          <RolesBasedRerouting
            allowedRoles={[
              TENANT_ROLES.companyOwner,
              TENANT_ROLES.companyManager,
              TENANT_ROLES.companyAssociate,
              TENANT_ROLES.companyMember,
            ]}
            homeUrl={homeUrl}
            tenantRole={tenantRole}
          >
            <DashboardLayout />
          </RolesBasedRerouting>
        </ProtectedRoute>
      ),
      children: [
        {
          element: <PropertiesView />,
          index: true,
        },
        {
          path: '/maktb/properties/:id',
          element: <EditProperty />,
        },
      ],
    },
    // properties ends

    // crm starts
    {
      path: '/maktb/crm',
      element: (
        <ProtectedRoute>
          <RolesBasedRerouting
            allowedRoles={[
              TENANT_ROLES.companyOwner,
              TENANT_ROLES.companyManager,
              TENANT_ROLES.companyAssociate,
              TENANT_ROLES.companyMember,
            ]}
            homeUrl={homeUrl}
            tenantRole={tenantRole}
          >
            <DashboardLayout />
          </RolesBasedRerouting>
        </ProtectedRoute>
      ),
      children: [
        {
          element: <Navigate to="/maktb/crm/deals" replace />,
        },
        {
          path: 'deals',
          element: <DealsView />,
        },

        {
          path: 'deals/:id',
          element: <EditDeal />,
        },

        {
          element: <Navigate to="/maktb/crm/contacts" replace />,
        },
        {
          path: 'contacts',
          element: <ContactsList />,
        },
        {
          path: 'contacts/add',
          element: <AddContact />,
        },
        {
          path: 'contacts/:id',
          element: <EditContact />,
        },
        {
          path: 'requests',
          element: (
            <RolesBasedRerouting
              allowedRoles={[TENANT_ROLES.companyOwner, TENANT_ROLES.companyManager, TENANT_ROLES.companyAssociate]}
              homeUrl={homeUrl}
              tenantRole={tenantRole}
            >
              <RequestsView />
            </RolesBasedRerouting>
          ),
        },
        {
          path: 'requests/add',
          element: (
            <RolesBasedRerouting
              allowedRoles={[TENANT_ROLES.companyOwner, TENANT_ROLES.companyManager, TENANT_ROLES.companyAssociate]}
              homeUrl={homeUrl}
              tenantRole={tenantRole}
            >
              <AddRequest />
            </RolesBasedRerouting>
          ),
        },
        {
          path: 'requests/:id',
          element: (
            <RolesBasedRerouting
              allowedRoles={[TENANT_ROLES.companyOwner, TENANT_ROLES.companyManager, TENANT_ROLES.companyAssociate]}
              homeUrl={homeUrl}
              tenantRole={tenantRole}
            >
              <EditRequest />
            </RolesBasedRerouting>
          ),
        },
        {
          element: <Navigate to="/subscription/summery" replace />,
        },
        {
          path: 'summery',
          element: <ProtectedRoute>{/* <PaymentDetails /> */}</ProtectedRoute>,
        },
        {
          element: <Navigate to="/subscription/plans/:packageId/checkout" replace />,
        },
        {
          element: <Navigate to="/subscription/payment-details" replace />,
        },
        {
          path: 'payment-details',
          element: <ProtectedRoute>{/* <PaymentDetails /> */}</ProtectedRoute>,
        },

        {
          element: <Navigate to="/subscription/expired" replace />,
        },
        {
          path: 'expired',
          element: (
            <ProtectedRoute>
              <Expired />
            </ProtectedRoute>
          ),
        },
      ],
    },
    // crm ends

    // services starts
    {
      path: '/maktb/services',
      element: (
        <ProtectedRoute>
          <RolesBasedRerouting
            allowedRoles={[TENANT_ROLES.companyOwner, TENANT_ROLES.companyManager, TENANT_ROLES.companyAssociate]}
            homeUrl={homeUrl}
            tenantRole={tenantRole}
          >
            <DashboardLayout />
          </RolesBasedRerouting>
        </ProtectedRoute>
      ),
      children: [
        {
          element: <Navigate to="/maktb/services" replace />,
        },
        {
          path: '/maktb/services',
          element: <ServicesView />,
        },
        {
          path: '/maktb/services/:id',
          element: <EditService />,
        },
      ],
    },
    // services ends

    // services starts
    {
      path: '/maktb/operations',
      element: (
        <ProtectedRoute>
          <DashboardLayout />
        </ProtectedRoute>
      ),
      children: [
        {
          path: '/maktb/operations/orders',
          element: <OrdersView />,
        },
        {
          path: '/maktb/operations/sales',
          element: <SalesView />,
        },
        {
          path: '/maktb/operations/orders/:id',
          element: <EditOrder />,
        },
      ],
    },
    // services ends

    // website starts
    {
      path: '/settings',
      element: (
        <ProtectedRoute>
          <RolesBasedRerouting allowedRoles={[TENANT_ROLES.companyOwner]} homeUrl={homeUrl} tenantRole={tenantRole}>
            <DashboardLayout />
          </RolesBasedRerouting>
        </ProtectedRoute>
      ),

      children: [
        {
          element: <Settings />,
          index: true,
        },
        {
          path: '/settings/account',
          element: <Account />,
        },
        {
          path: '/settings/payments',
          element: <Payments />,
        },
        {
          path: '/settings/subscriptions',
          element: <Subscriptions />,
        },
      ],
    },
    // website ends

    {
      path: '*',
      element: <NotFound />,
    },
  ]);
}
