import { all } from 'redux-saga/effects';

import { authSaga } from './auth/sagas';
import { contactsSaga } from './contacts/sagas';
import { couponSaga } from './coupon/sagas';
import { dealsSaga } from './deals/sagas';
import { notesSaga } from './notes/sagas';
import { notificationsSaga } from './notifications/sagas';
import { ordersSaga } from './order/sagas';
import { productSaga } from './product/sagas';
import { projectsSaga } from './projects/sagas';
import { propertiesSaga } from './properties/sagas';
import { subscriptionSaga } from './subscription/sagas';
import { tOrdersSaga } from './t-orders/sagas';
import { reservationsSaga } from './t-reservations/sagas';
import { tServicesSaga } from './t-services/sagas';
import { teamSaga } from './team/sagas';
import { transactionSaga } from './transaction/sagas';
import { userSaga } from './user/sagas';
import { utilsSaga } from './utils/sagas';
import { workspaceSaga } from './workspace/sagas';

export default function* rootSaga() {
  yield all([
    authSaga(),
    userSaga(),
    subscriptionSaga(),
    transactionSaga(),
    productSaga(),
    teamSaga(),
    ordersSaga(),
    contactsSaga(),
    propertiesSaga(),
    projectsSaga(),
    dealsSaga(),
    notesSaga(),
    notificationsSaga(),
    utilsSaga(),
    couponSaga(),
    workspaceSaga(),
    reservationsSaga(),
    tOrdersSaga(),
    tServicesSaga(),
  ]);
}
