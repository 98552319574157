import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
// eslint-disable-next-line import/no-named-as-default
import TServiceServices from './services';

export function* getServicesSaga({ payload }) {
  try {
    const response = yield call(TServiceServices.getServices, payload);
    yield put(actions.getServicesSuccess(response.data));
  } catch (err) {
    yield put(actions.getServicesFailure(err));
  }
}

export function* getServiceSaga({ payload }) {
  try {
    const response = yield call(TServiceServices.getService, payload.id);
    yield put(actions.getServiceSuccess(response.data));
  } catch (err) {
    yield put(actions.getServiceFailure(err));
  }
}

export function* createServiceSaga({ payload }) {
  const { callback, ...values } = payload;
  try {
    const response = yield call(TServiceServices.createService, values);
    yield put(actions.createServiceSuccess(response.data));
    callback?.('success', response.data);
  } catch (err) {
    const errorMessage = err.response?.data?.message || 'Error';
    yield put(actions.createServiceFailure(err));
    callback?.('error', errorMessage);
  }
}

export function* editServiceSaga({ payload }) {
  const { id, callback, ...values } = payload;
  try {
    const response = yield call(() => TServiceServices.editService(id, values));
    yield put(actions.editServiceSuccess(response.data));
    callback?.('success', response.data);
  } catch (err) {
    const errorMessage = err.response?.data?.message || 'Error';

    yield put(actions.editServiceFailure(err));
    callback?.('error', errorMessage);
  }
}

export function* tServicesSaga() {
  yield all([
    takeLatest(actions.getServicesRequest, getServicesSaga),
    takeLatest(actions.getServiceRequest, getServiceSaga),
    takeLatest(actions.createServiceRequest, createServiceSaga),
    takeLatest(actions.editServiceRequest, editServiceSaga),
  ]);
}
