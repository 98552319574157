// @mui
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
// hooks
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

// components
import Page from '../../../components/Page';
import useLocales from '../../../hooks/useLocales';
import useSettings from '../../../hooks/useSettings';
import { selectors as subscriptionSelectors } from '../../../models/subscription/reducers';
import * as userActions from '../../../models/user/actions';
import * as workspaceActions from '../../../models/workspace/actions';
import { selectors as workspaceSelectors } from '../../../models/workspace/reducers';

// ----------------------------------------------------------------------

export default function SocialMediaLinks() {
  const { translate } = useLocales();
  const dispatch = useDispatch();
  const { themeStretch } = useSettings();

  const activeSubscriptions = useSelector(subscriptionSelectors.activeSubscriptions);
  const activeMaktbSubscription = activeSubscriptions?.find((sub) => sub.solution === 'digital_office');

  // as an object define baseUrl for all social media:
  const socialMediaLinksBaseUrl = {
    x: 'https://www.x.com/YourAccount',
    instagram: 'https://www.instagram.com/YourAccount',
    tiktok: 'https://www.tiktok.com/@YourAccount',
    whatsapp: 'https://wa.me/+966501234567',
    snapchat: 'https://www.snapchat.com/add/YourAccount',
    emailAddress: 'you@company.com',
    phoneNumber: '+966501234567',
    googleTagManager: 'G-123ABC456',
    snapchatPixel: 'xxx-xxx-xxx',
    metaPixel: '123456789',
    tiktokPixel: '123456789',
  };

  const workspaceInformationDataLoading = useSelector(workspaceSelectors.workspaceInformationDataLoading);
  const workspaceInformationData = useSelector(workspaceSelectors.workspaceInformationData);

  useEffect(() => {
    dispatch(workspaceActions.getWorkspaceInformationRequest());
    dispatch(userActions.getUserInformationRequest());
  }, []);

  const callback = (type) => {
    if (type === 'success') {
      toast('Success!', { type: 'success' });
      dispatch(workspaceActions.getWorkspaceInformationRequest());
      dispatch(userActions.getUserWorkspacesRequest());
      dispatch(userActions.getUserInformationRequest());
    } else {
      toast('Error!', { type: 'error' });
    }
  };

  const formik = useFormik({
    onSubmit: (values) => {
      dispatch(workspaceActions.editWorkspaceInformationRequest({ ...values, callback }));
    },
    initialValues: {
      name_en: workspaceInformationData?.name_en || '',
      name_ar: workspaceInformationData?.name_ar || '',
      logo_url: workspaceInformationData?.logo_url || '',
      contact_phone_call: workspaceInformationData?.contact_phone_call || '',
      contact_email: workspaceInformationData?.contact_email || '',
      x_url: workspaceInformationData?.x_url || '',
      instagram_url: workspaceInformationData?.instagram_url || '',
      snapchat_url: workspaceInformationData?.snapchat_url || '',
      tiktok_url: workspaceInformationData?.tiktok_url || '',
      whatsapp_url: workspaceInformationData?.whatsapp_url || '',
      whatsapp_floating_button: workspaceInformationData?.whatsapp_floating_button || false,
      gtag_id: workspaceInformationData?.gtag_id || '',
      google_tag_enabled: workspaceInformationData?.google_tag_enabled || false,
      snapchat_pixel_id: workspaceInformationData?.snapchat_pixel_id || '',
      snapchat_pixel_enabled: workspaceInformationData?.snapchat_pixel_enabled || false,
      tiktok_pixel_id: workspaceInformationData?.tiktok_pixel_id || '',
      tiktok_pixel_enabled: workspaceInformationData?.tiktok_pixel_enabled || false,
      meta_pixel_id: workspaceInformationData?.meta_pixel_id || '',
      meta_pixel_enabled: workspaceInformationData?.meta_pixel_enabled || false,
      mot_iwaa_number: workspaceInformationData?.mot_iwaa_number || '',
      rega_fal_number: workspaceInformationData?.rega_fal_number || '',
      mc_cr_number: workspaceInformationData?.mc_cr_number || '',
      hrsd_freelance_number: workspaceInformationData?.hrsd_freelance_number || '',
      zatca_certificate_number: workspaceInformationData?.zatca_certificate_number || '',
    },
    enableReinitialize: true,
  });

  return (
    <Page title={translate('web.socialMediaLinks.title')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Typography variant="h3" component="h1" paragraph>
          {translate('web.socialMediaLinks.title')}
        </Typography>

        <Grid container justifyContent={'center'}>
          <Grid item xs={12} md={12}>
            <Card>
              <CardContent>
                <form onSubmit={formik.handleSubmit}>
                  <Grid container>
                    {/* x */}
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={6} dir="ltr" textAlign={'left'}>
                        <TextField
                          label={translate('web.socialMediaLinks.x')}
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          value={formik.values.x_url}
                          onChange={formik.handleChange}
                          name="x_url"
                        />
                      </Grid>
                      <Grid item xs={6} dir="ltr" textAlign={'left'}>
                        {socialMediaLinksBaseUrl.x}
                      </Grid>
                    </Grid>

                    {/* instagram */}
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={6} dir="ltr" textAlign={'left'}>
                        <TextField
                          label={translate('web.socialMediaLinks.instagram')}
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          value={formik.values.instagram_url}
                          onChange={formik.handleChange}
                          name="instagram_url"
                        />
                      </Grid>
                      <Grid item xs={6} dir="ltr" textAlign={'left'}>
                        {socialMediaLinksBaseUrl.instagram}
                      </Grid>
                    </Grid>

                    {/* snapchat */}
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={6} dir="ltr" textAlign={'left'}>
                        <TextField
                          label={translate('web.socialMediaLinks.snapchat')}
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          value={formik.values.snapchat_url}
                          onChange={formik.handleChange}
                          name="snapchat_url"
                        />
                      </Grid>
                      <Grid item xs={6} dir="ltr" textAlign={'left'}>
                        {socialMediaLinksBaseUrl.snapchat}
                      </Grid>
                    </Grid>

                    {/* tiktok */}
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={6} dir="ltr" textAlign={'left'}>
                        <TextField
                          label={translate('web.socialMediaLinks.tiktok')}
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          value={formik.values.tiktok_url}
                          onChange={formik.handleChange}
                          name="tiktok_url"
                        />
                      </Grid>
                      <Grid item xs={6} dir="ltr" textAlign={'left'}>
                        {socialMediaLinksBaseUrl.tiktok}
                      </Grid>
                    </Grid>

                    {/* whatsapp */}
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={6} dir="ltr" textAlign={'left'}>
                        <TextField
                          label={translate('web.socialMediaLinks.whatsapp')}
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          value={formik.values.whatsapp_url}
                          onChange={formik.handleChange}
                          name="whatsapp_url"
                        />
                      </Grid>
                      <Grid item xs={6} dir="ltr" textAlign={'left'}>
                        {socialMediaLinksBaseUrl.whatsapp}
                      </Grid>

                      <Grid item xs={12} textAlign={'left'} mb={3}>
                        <FormControlLabel
                          label={translate('web.socialMediaLinks.whatsappFloatingButton')}
                          control={
                            <Checkbox
                              name="whatsapp_floating_button"
                              checked={formik.values.whatsapp_floating_button}
                              disabled={activeMaktbSubscription?.is_trial}
                            />
                          }
                          onChange={formik.handleChange}
                        />
                        {activeMaktbSubscription?.is_trial && (
                          <span
                            style={{
                              backgroundColor: '#ddd',
                              marginLeft: 7,
                              marginRight: 7,
                              paddingRight: 7,
                              paddingLeft: 7,
                              paddingTop: 3,
                              paddingBottom: 3,
                              borderRadius: 5,
                            }}
                          >
                            {translate('paidPlan')}
                          </span>
                        )}
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={6} dir="ltr" textAlign={'left'}>
                        <TextField
                          label={translate('web.socialMediaLinks.email')}
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          value={formik.values.contact_email}
                          onChange={formik.handleChange}
                          name="contact_email"
                        />
                      </Grid>
                      <Grid item xs={6} dir="ltr" textAlign={'left'}>
                        {socialMediaLinksBaseUrl.emailAddress}
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={6} dir="ltr" textAlign={'left'}>
                        <TextField
                          label={translate('web.socialMediaLinks.phoneNumber')}
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          value={formik.values.contact_phone_call}
                          onChange={formik.handleChange}
                          name="contact_phone_call"
                        />
                      </Grid>
                      <Grid item xs={6} dir="ltr" textAlign={'left'}>
                        {socialMediaLinksBaseUrl.phoneNumber}
                      </Grid>
                    </Grid>

                    {/* google tag manager */}
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={6} dir="ltr" textAlign={'left'}>
                        <TextField
                          label={translate('web.socialMediaLinks.googleTagManager')}
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          value={formik.values.gtag_id}
                          onChange={formik.handleChange}
                          name="gtag_id"
                        />
                      </Grid>
                      <Grid item xs={6} dir="ltr" textAlign={'left'}>
                        {socialMediaLinksBaseUrl.googleTagManager}
                      </Grid>

                      <Grid item xs={12} textAlign={'left'} mb={3}>
                        <FormControlLabel
                          label={translate('web.socialMediaLinks.gTagRadio')}
                          control={
                            <Checkbox
                              name="google_tag_enabled"
                              checked={formik.values.google_tag_enabled}
                              disabled={activeMaktbSubscription?.is_trial}
                            />
                          }
                          onChange={formik.handleChange}
                        />
                        {activeMaktbSubscription?.is_trial && (
                          <span
                            style={{
                              backgroundColor: '#ddd',
                              marginLeft: 7,
                              marginRight: 7,
                              paddingRight: 7,
                              paddingLeft: 7,
                              paddingTop: 3,
                              paddingBottom: 3,
                              borderRadius: 5,
                            }}
                          >
                            {translate('paidPlan')}
                          </span>
                        )}
                      </Grid>
                    </Grid>

                    {/* snapchat pixel */}
                    <Grid container spacing={2} alignItems="center" mb={3}>
                      <Grid item xs={6} dir="ltr" textAlign={'left'}>
                        <TextField
                          label={translate('web.socialMediaLinks.snapchatPixel')}
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          value={formik.values.snapchat_pixel_id}
                          onChange={formik.handleChange}
                          name="snapchat_pixel_id"
                        />
                      </Grid>
                      <Grid item xs={6} dir="ltr" textAlign={'left'}>
                        {socialMediaLinksBaseUrl.snapchatPixel}
                      </Grid>

                      <Grid item xs={12} textAlign={'left'}>
                        <FormControlLabel
                          label={translate('web.socialMediaLinks.snapchatPixelRadio')}
                          control={
                            <Checkbox
                              name="snapchat_pixel_enabled"
                              checked={formik.values.snapchat_pixel_enabled}
                              disabled={activeMaktbSubscription?.is_trial}
                            />
                          }
                          onChange={formik.handleChange}
                        />
                        {activeMaktbSubscription?.is_trial && (
                          <span
                            style={{
                              backgroundColor: '#ddd',
                              marginLeft: 7,
                              marginRight: 7,
                              paddingRight: 7,
                              paddingLeft: 7,
                              paddingTop: 3,
                              paddingBottom: 3,
                              borderRadius: 5,
                            }}
                          >
                            {translate('paidPlan')}
                          </span>
                        )}
                      </Grid>
                    </Grid>

                    {/* tiktok pixel */}
                    <Grid container spacing={2} alignItems="center" mb={3}>
                      <Grid item xs={6} dir="ltr" textAlign={'left'}>
                        <TextField
                          label={translate('web.socialMediaLinks.tiktokPixel')}
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          value={formik.values.tiktok_pixel_id}
                          onChange={formik.handleChange}
                          name="tiktok_pixel_id"
                        />
                      </Grid>
                      <Grid item xs={6} dir="ltr" textAlign={'left'}>
                        {socialMediaLinksBaseUrl.tiktokPixel}
                      </Grid>

                      <Grid item xs={12} textAlign={'left'}>
                        <FormControlLabel
                          label={translate('web.socialMediaLinks.tiktokPixelRadio')}
                          control={
                            <Checkbox
                              name="tiktok_pixel_enabled"
                              checked={formik.values.tiktok_pixel_enabled}
                              disabled={activeMaktbSubscription?.is_trial}
                            />
                          }
                          onChange={formik.handleChange}
                        />
                        {activeMaktbSubscription?.is_trial && (
                          <span
                            style={{
                              backgroundColor: '#ddd',
                              marginLeft: 7,
                              marginRight: 7,
                              paddingRight: 7,
                              paddingLeft: 7,
                              paddingTop: 3,
                              paddingBottom: 3,
                              borderRadius: 5,
                            }}
                          >
                            {translate('paidPlan')}
                          </span>
                        )}
                      </Grid>
                    </Grid>

                    {/* meta */}
                    <Grid container spacing={2} alignItems="center" mb={3}>
                      <Grid item xs={6} dir="ltr" textAlign={'left'}>
                        <TextField
                          label={translate('web.socialMediaLinks.metaPixel')}
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          value={formik.values.meta_pixel_id}
                          onChange={formik.handleChange}
                          name="meta_pixel_id"
                        />
                      </Grid>
                      <Grid item xs={6} dir="ltr" textAlign={'left'}>
                        {socialMediaLinksBaseUrl.metaPixel}
                      </Grid>

                      <Grid item xs={12} textAlign={'left'}>
                        <FormControlLabel
                          label={translate('web.socialMediaLinks.metaPixelRadio')}
                          control={
                            <Checkbox
                              name="meta_pixel_enabled"
                              checked={formik.values.meta_pixel_enabled}
                              disabled={activeMaktbSubscription?.is_trial}
                            />
                          }
                          onChange={formik.handleChange}
                        />
                        {activeMaktbSubscription?.is_trial && (
                          <span
                            style={{
                              backgroundColor: '#ddd',
                              marginLeft: 7,
                              marginRight: 7,
                              paddingRight: 7,
                              paddingLeft: 7,
                              paddingTop: 3,
                              paddingBottom: 3,
                              borderRadius: 5,
                            }}
                          >
                            {translate('paidPlan')}
                          </span>
                        )}
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: 'flex',
                        alignItems: 'end',
                        height: '100%',
                        justifyContent: 'end',
                      }}
                    >
                      <Button
                        disabled={!formik.dirty}
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{
                          color: 'white',
                        }}
                      >
                        {translate('workspace.save')}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
